<template>
  <div>
    <!-- 待扩展专门写通用快速配置方式（需要重构部分逻辑），此处暂时仅写几个常用的 -->
    <a-menu :selectable="false">
      <a-menu-item
        key="1"
        @click="clearSettings"
      >重置
      </a-menu-item>
      <a-menu-item
        key="2"
        @click="randMaskingOut"
      >
        <a-tooltip>
          <template #title>当前蒙版:{{maskingOutTitle}}</template>
          随机蒙版
        </a-tooltip>
      </a-menu-item>
      <a-menu-item
        key="3"
        @click="randPrimaryColor"
      >
        <a-tooltip>
          <template #title>当前主色:{{primaryColor}}</template>
          随机主色
        </a-tooltip>
      </a-menu-item>
      <a-menu-item
        key="4"
        @click="menuShowFlagChange"
      >
        {{menuShowFlag ? '隐藏': '显示'}}菜单栏
      </a-menu-item>
      <a-menu-item
        key="5"
        @click="()=>{aboutModalVisible = !aboutModalVisible}"
      >关于</a-menu-item>
    </a-menu>
    <a-modal
      v-model:visible="aboutModalVisible"
      title="关于"
    >
      <about-content></about-content>
    </a-modal>
  </div>
</template>

<script>
import { clearCache } from '@/assets/js/cache.js'
import { Modal } from 'ant-design-vue';
import { rand } from '@vueuse/shared';
import { advancedConfigValueChange, themeVarChange } from './SettingCenter.js'
import maskingsData from '@/components/MaskingOut/maskingsData.json'
import { themeSetting, advancedConfigSetting } from '@/assets/js/defaultSettings.js'
import AboutContent from '@/components/SettingCenter/AboutContent'
export default {
  components:{
    AboutContent
  },
  setup(){
    var selfSettings={}
    return{
      selfSettings
    }
  },
  data(){
    return{
      primaryColor:'',
      maskingOutTitle:'',
      menuShowFlag:true,
      aboutModalVisible:false
    }
  },
  mounted(){
    this.primaryColor = themeSetting.getData()?.primaryColor;
    this.maskingOutTitle = maskingsData.dataSource[advancedConfigSetting.getData()?.maskingOutMode].title;
    this.menuShowFlag = advancedConfigSetting.getData()?.menuShowFlag;
  },
  methods:{
    openEmpty(){
      this.$message.info("功能还未实现")
    },
    clearSettings(){
      Modal.confirm({
        title: '提示',
        content: "确认清除缓存并刷新页面?",
        onOk:()=> {
          clearCache();
          this.$router.go(0);
        },
        onCancel:() =>{
        },
      });
    },
    randPrimaryColor(){
      let red = rand(0,255);
      let green = rand(0,255);
      let blue = rand(0,255);
      let transparent = rand(0,255)/255;
      this.primaryColor = `rgba(${red}, ${green}, ${blue}, ${transparent})`;
      themeVarChange(null, "primaryColor", this.primaryColor);
      this.$message.info("随机主色设置为："+this.primaryColor);
    },
    randMaskingOut(){
      let num = rand(0,3);
      advancedConfigValueChange(null, "maskingOutMode", num);
      this.maskingOutTitle = maskingsData.dataSource[num].title;
      this.$message.info("随机蒙版设置为："+this.maskingOutTitle);
    },
    menuShowFlagChange(){
      this.menuShowFlag = !this.menuShowFlag;
      advancedConfigValueChange(null, "menuShowFlag", this.menuShowFlag);
    },
    openAbout(){
    }
  }
}
</script>

<style>
</style>