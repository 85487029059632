<!-- https://codepen.io/awinnett/pen/mdLOpro -->
<template>
  <div class="molecule-wrapper">
    <div class="molecule">
      <svg
        width="500"
        height="250"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="none"
      >
        <path
          id="outer-ring-1"
          d="M25,500
				a450,250 0 1,0 950,0
				a450,250 0 1,0 -950,0"
          class="path-st"
        ></path>
        <g
          id="oval-area-1"
          transform="translate(-190,-245)"
        >
          <g>
            <ellipse
              rx=5
              ry=8
              cx=190
              cy=250
              fill="black"
              stroke="black"
              stroke-width="2"
            />
          </g>
          <animateMotion
            xlink:href="#oval-area-1"
            attributeName="motion"
            attributeType="XML"
            additive="sum"
            dur="6s"
            begin="-5s"
            repeatCount="indefinite"
          >
            <mpath xlink:href="#outer-ring-1" />
          </animateMotion>
        </g>
      </svg>

      <svg
        width="500"
        height="250"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="none"
      >
        <path
          id="outer-ring-2"
          d="M25,500
				a450,250 0 1,0 950,0
				a450,250 0 1,0 -950,0"
          class="path-st"
        ></path>
        <g
          id="oval-area-2"
          transform="translate(-190,-245)"
        >
          <g>
            <ellipse
              rx=5
              ry=8
              cx=190
              cy=250
              fill="black"
              stroke="black"
              stroke-width="2"
            />
          </g>
          <animateMotion
            xlink:href="#oval-area-2"
            attributeName="motion"
            attributeType="XML"
            additive="sum"
            dur="6s"
            begin="-3s"
            repeatCount="indefinite"
          >
            <mpath xlink:href="#outer-ring-2" />
          </animateMotion>
        </g>
      </svg>

      <svg
        width="500"
        height="250"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="none"
      >
        <path
          id="outer-ring-3"
          d="M25,500
				a450,250 0 1,0 950,0
				a450,250 0 1,0 -950,0"
          class="path-st"
        ></path>
        <g
          id="oval-area-3"
          transform="translate(-190,-245)"
        >
          <g>
            <ellipse
              rx=5
              ry=8
              cx=190
              cy=250
              fill="black"
              stroke="black"
              stroke-width="2"
            />
          </g>
          <animateMotion
            xlink:href="#oval-area-3"
            attributeName="motion"
            attributeType="XML"
            additive="sum"
            dur="6s"
            begin="-1s"
            repeatCount="indefinite"
          >
            <mpath xlink:href="#outer-ring-3" />
          </animateMotion>
        </g>
      </svg>

      <svg
        width="300"
        height="250"
        viewBox="0 0 1000 1000"
        preserveAspectRatio="none"
      >
        <path
          id="outer-ring-4"
          d="M25,500
				a300,200 0 1,0 950,0
				a300,200 0 1,0 -950,0"
          class="path-st"
        ></path>
        <g
          id="oval-area-4"
          transform="translate(-190,-245)"
        >
          <g>
            <ellipse
              rx=5
              ry=8
              cx=190
              cy=250
              fill="black"
              stroke="black"
              stroke-width="2"
            />
          </g>
          <animateMotion
            xlink:href="#oval-area-4"
            attributeName="motion"
            attributeType="XML"
            additive="sum"
            dur="6s"
            repeatCount="indefinite"
          >
            <mpath xlink:href="#outer-ring-4" />
          </animateMotion>
        </g>
      </svg>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
// body {
// 	background-color: black;
// }

.molecule-wrapper {
  width: calc(100% - 200px);
  margin: 100px;
  transform: scale(0.6, 0.6);
}

.molecule {
  position: relative;
  width: 100%;
  border: 1px solid white;
  border-radius: 50%;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  svg {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 150%;
    height: 65%;

    .path-st {
      fill: none;
      stroke: rgba(0, 0, 0, 0.1);
      stroke-width: 2;
      stroke-dasharray: 25px;
    }

    &:nth-child(1) {
      transform: translate(-50%, -50%);
    }

    &:nth-child(2) {
      transform: translate(-50%, -50%) rotate(-25deg);
    }

    &:nth-child(3) {
      transform: translate(-50%, -50%) rotate(25deg);
    }

    &:nth-child(4) {
      width: 100%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
</style>