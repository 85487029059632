<template>
  <div class="masking-body">
    <div v-if="mode==mapNameMaskings.get('SnowMasking')?.mode">
      <snow-masking></snow-masking>
    </div>
    <div v-else-if="mode==mapNameMaskings.get('SolarSystemMasking')?.mode">
      <solar-system-masking></solar-system-masking>
    </div>
    <div v-else-if="mode==mapNameMaskings.get('GradientBackground')?.mode">
      <gradient-background></gradient-background>
    </div>
    <div v-else-if="mode==mapNameMaskings.get('MoleculeOrbitMasking')?.mode">
      <molecule-orbit-masking></molecule-orbit-masking>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import SnowMasking from '@/components/MaskingOut/Background/SnowMasking'
import SolarSystemMasking from '@/components/MaskingOut/Background/SolarSystemMasking'
import GradientBackground from '@/components/MaskingOut/Background/GradientBackground'
import MoleculeOrbitMasking from '@/components/MaskingOut/Background/MoleculeOrbitMasking'
import { watch } from '@vue/runtime-core'
import { indexAddStep } from '@/assets/js/array.js'
import maskingsData from '@/components/MaskingOut/maskingsData.json'

export default {
  components:{
    SnowMasking,
    SolarSystemMasking,
    GradientBackground,
    MoleculeOrbitMasking
  },
  props:{
    mode:{
      type:Number,
      default:1
    },
    getCount:{
      type:Function,
      default:(con)=>con
    }
  },
  setup(props, context){
    var maskings = maskingsData.dataSource;
    var mapModeMaskings = new Map();
    for(let i=0; i<maskings.length; i++){
      mapModeMaskings.set(i, {...maskings[i],mode:i})
    }
    var mapNameMaskings = new Map();
    for(let i=0; i<maskings.length; i++){
      mapNameMaskings.set(maskings[i].name, {...maskings[i],mode:i})
    }

    watch(
      () => props.mode,
      (newProp) => {
        let val = {nowValue:mapModeMaskings.get(newProp), nextValue:mapModeMaskings.get(indexAddStep(newProp, maskings.length))}
        context.emit("maskingchange",val)
      }
    );
    //调用传入的获取主题数量的函数方法
    props.getCount(maskings.length)
    //初始载入组件的时候就触发事件给父组件，以免夫主键使用空值
    let val = {
      nowValue: mapModeMaskings.get(props.mode), 
      nextValue: mapModeMaskings.get(indexAddStep(props.mode, maskings.length))
      }
    context.emit("maskingchange",val)
    return{
      mapNameMaskings
    }
  },
  methods:{
  }
}
</script>

<style lang="scss">
.masking-body {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  pointer-events: none;
}
</style>