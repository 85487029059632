import { advancedConfigSetting, themeSetting, configProviderSetting } from '@/assets/js/defaultSettings.js';

/**
 * 设置高级设置
 * @param {Object} advancedConfigValue 设置对象，为空时获取缓存
 * @param {String} type 修改项（字段名）
 * @param {Object} value 值
 */
const advancedConfigValueChange = (advancedConfigValue, type, value) => {
  if (!advancedConfigValue) advancedConfigValue = advancedConfigSetting.getData();
  Object.assign(advancedConfigValue, {
    [type]: value,
  });
  advancedConfigSetting.setData(advancedConfigValue)
}

/**
 * 设置主题变量（颜色等）
 * @param {Object} themeVar 设置对象，为空时获取缓存
 * @param {String} type 修改项（字段名）
 * @param {Object} value 值 
 */
const themeVarChange = (themeVar, type, value) => {
  if (!themeVar) themeVar = themeSetting.getData();
  Object.assign(themeVar, {
    [type]: value,
  });
  themeSetting.setData(themeVar)
}

/**
 * 设置antd组件配置
 * @param {Object} antdConfigProviderValue 设置对象，为空时获取缓存
 * @param {String} type 修改项（字段名）
 * @param {Object} value 值 
 */
const antdConfigProviderValueChange = (antdConfigProviderValue, type, value) => {
  if (!antdConfigProviderValue) antdConfigProviderValue = configProviderSetting.getData();
  Object.assign(antdConfigProviderValue, {
    [type]: value,
  });
  configProviderSetting.setData(antdConfigProviderValue)
}

export default { advancedConfigValueChange, themeVarChange, antdConfigProviderValueChange }
export { advancedConfigValueChange, themeVarChange, antdConfigProviderValueChange }