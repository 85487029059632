<template>
  <div>
    <a-menu
      id="menu-st"
      :mode="mode"
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      :inlineCollapsed="false"
      :theme="theme"
    >
      <template
        v-for="item in list"
        :key="item.key"
      >
        <template v-if="!item.children">
          <a-menu-item
            :key="item.key"
            @click="setOpenKeys(item)"
          >
            <template
              #icon
              v-if="item.icon"
            >
              <component :is="$antIcons[item.icon]" />
            </template>
            <template
              #icon
              v-else
            >
              <component :is="$antIcons['FileOutlined']" />
            </template>
            <template v-if="item.blank">
              <a
                :href="'/#'+item.path"
                target="_blank"
              >{{item.name}}</a>
            </template>
            <template v-else>
              <router-link
                v-if="!callBackTag"
                :to="{ ...item, path:(item.path ? item.path : '/')}"
              >{{item.name}}</router-link>
              <span v-else>{{item.name}}</span>
            </template>
          </a-menu-item>
        </template>
        <template v-else>
          <sub-menu
            :key="item.key"
            :menu-info="item"
            :callBackTag="callBackTag"
            @setOpenKeys="setOpenKeys"
          />
        </template>
      </template>
    </a-menu>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import { menus } from '@/router/index.js';
import { routerCache } from "@/assets/js/cache.js";
// import { useRouter } from "vue-router";
import store from '@/store/index.js'
const SubMenu = {
  name: 'SubMenu',
  props: {
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
    callBackTag:{
      type: Boolean,
      default: false
    }
  },
  template: `
    <a-sub-menu :key="menuInfo.key">
      <template #icon v-if="menuInfo.icon">
        <component :is="$antIcons[menuInfo.icon]" />
      </template>
      <template #icon v-else>
        <component :is="$antIcons['BarsOutlined']" />
      </template>
      <template #title>{{ menuInfo.name }}</template>
      <template v-for="item in menuInfo.children" :key="item.key">
        <template v-if="!item.children">
          <a-menu-item :key="item.key" @click="setOpenKeys(item)">
            <template #icon v-if="item.icon">
              <component :is="$antIcons[item.icon]" />
            </template>
            <template #icon v-else>
              <component :is="$antIcons['FileOutlined']" /> 
            </template>
            <template v-if="item.blank">
              <a :href="'/#'+item.path" target="_blank">{{item.name}}</a>
            </template>
            <template v-else>
              <router-link v-if="!callBackTag" :to="item.path ? item.path : '/'">{{item.name}}</router-link>
              <span v-else>{{item.name}}</span>
            </template>
          </a-menu-item>
        </template>
        <template v-else>
          <sub-menu @setOpenKeys="setOpenKeys" :callBackTag="callBackTag" :menu-info="item" :key="item.name" />
        </template>
      </template>
    </a-sub-menu>
  `,
  components: {
    
  },
  methods:{
    setOpenKeys(item){
      this.$emit("setOpenKeys",item)
    }
  }
};

export default defineComponent({
  components: {
    'sub-menu': SubMenu,
  },
  props:{
    mode:{
      type:String,
      default:"horizontal"
    },
    theme:{
      type:String,
      default:"light"
    },
    sourceData:{
      type:Array,
      default:()=>[]
    },
    callBackTag:{
      type: Boolean,
      default: false
    },
    defaultSelectKeys:{
      type: Array,
      default:()=>[]
    }
  },
  setup() {
  },
  data(){
    const list = this.sourceData.length >0 ? this.sourceData : menus()
    
    var openKeys = ['0'];
    var selectedKeys = ['0'];

    if(this.defaultSelectKeys.length>0){
      openKeys = this.defaultSelectKeys;
      selectedKeys = this.defaultSelectKeys;
    }
    else {
      let route = store.state.route;
      if(!route)route=routerCache.getCache();
      var key = route?.name+'-'+route?.path;
      openKeys =  route ? [key] : ['0'];
      selectedKeys =  route ? [key] : ['0'];
    }

    // watch(()=>this.defaultSelectKeys,(newValue)=>{
    //   openKeys = newValue;
    //   selectedKeys = newValue;
    // console.info("defaultSelectKeys:",this.defaultSelectKeys)
    // console.info("openKeys:",openKeys)
    // console.info("selectedKeys:",selectedKeys)
    // },{deep:true});

    return {
      list,
      collapsed:false,
      openKeys,
      selectedKeys,
      store
    };
  },
  mounted(){
    if(this.sourceData.length==0){
      watch(()=>store.state.route,(now)=>{    
        var key = now ? [now.name+'-'+now.path] : ['0'];
        this.openKeys =  key;
        this.selectedKeys =  key;
      
      })
    }
  },
  methods:{
    setOpenKeys(item){
      let keys = [item.key]
      this.openKeys = keys
      this.selectedKeys = keys
      if(this.callBackTag){
        this.linkClick(item);
        return;
      }
      
      // routerCache.setCache(item)
      // this.$store.dispatch('subRoute',item)
    },
    linkClick(item){
      this.$emit("linkClick",item)
    }
  }
});
</script>

<style lang="less">
#menu-st {
  height: 64px;
  background: transparent;
  color: v-bind("store.state.defaultSettings.themeModifyVars.textColor");
  /*  color: red; */
  border-bottom: 0;
}
</style>