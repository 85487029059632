/**
 * 设置缓存
 * @param {String} key 键名
 * @param {Object} data 键值
 */
const setCache = (key, data) => {
  if (!data && data != false) alert("缓存不可存储为空！！！")
  else {
    let dataJson = JSON.stringify(data)
    localStorage.setItem(key, dataJson)
  }
}

/**
 * 获取缓存
 * @param {String} key 键名
 * @returns 键值
 */
const getCache = (key) => {
  let dataJson = localStorage.getItem(key)
  return JSON.parse(dataJson)
}

/**
 * 移除缓存
 * @param {String} key 键名
 */
const removeCache = (key) => {
  localStorage.removeItem(key)
}

/**
 * 清除所有缓存
 */
const clearCache = () => {
  localStorage.clear()
}


/**
 * 自定义设置的缓存
 */
const setingsCache = {
  /**
   * 自定义设置缓存key
   */
  cacheKey: 'settings',
  /**
   * 设置自定义设置缓存
   * @param {Object} data 设置对象
   */
  setCache: (data) => {
    setCache(setingsCache.cacheKey, data)
  },
  /**
   * 获取自定义设置缓存
   * @returns {Object} 当前自定义设置缓存
   */
  getCache: () => {
    return getCache(setingsCache.cacheKey)
  },
  /**
   * 移除自定义设置缓存
   */
  removeCache: () => {
    removeCache(setingsCache.cacheKey)
  }
}

/**
 * 路由的缓存
 */
const routerCache = {
  /**
   * 路由缓存key
   */
  cacheKey: 'router',
  /**
   * 设置路由缓存
   * @param {Object} data 路由对象
   */
  setCache: (data) => {
    setCache(routerCache.cacheKey, data);
  },
  /**
   * 获取当前缓存路由
   * @returns {Object} 当前缓存路由对象
   */
  getCache: () => {
    return getCache(routerCache.cacheKey)
  },
  /**
   * 移除当前路由缓存
   */
  removeCache: () => {
    removeCache(routerCache.cacheKey)
  }
}

/**
 * 内容缓存
 */
const contentCache = {
  /**
   * 路由缓存key
   */
  cacheKey: 'content',
  /**
   * 设置缓存
   * @param {Object} data 路由对象
   */
  setCache: (data) => {
    setCache(contentCache.cacheKey, data);
  },
  /**
   * 获取当前缓存
   * @returns {Object} 当前缓存路由对象
   */
  getCache: () => {
    return getCache(contentCache.cacheKey)
  },
  /**
   * 移除当前缓存
   */
  removeCache: () => {
    removeCache(contentCache.cacheKey)
  }
}

export default { setCache, getCache, removeCache, clearCache }

export { setingsCache, routerCache, contentCache, setCache, getCache, removeCache, clearCache }
