<template>
  <div :style="{ minWidth:'300px'}">
    <a-input-group
      compact
      :style="{display: 'flex'}"
    >
      <!-- <a-input
        v-model:value="valueX"
        @change="e => onColorChange(e)"
        :style="{width:'30%'}"
      ></a-input> -->
      <input
        class="color-input"
        type="color"
        :style="{borderRadius:0, width:'20%'}"
        v-model="valueX"
        @input="e => onColorChange(e)"
      />
      <div :style="{width:'70%',marginLeft:'15px'}">
        <a-select
          mode="combobox"
          v-if="mode==2"
          placeholder="预定义颜色"
          v-model:value="valueX"
          @select="value=>onColorChangeValue(value)"
        >
          <a-select-option
            v-for="(item) in defaultValues"
            :key="item"
            :value="item"
          >
            <a-tag :color="item">{{item}}</a-tag>
          </a-select-option>
        </a-select>
        <a-radio-group
          v-else-if="mode==3"
          v-model:value="valueX"
          @change="e=>radioChange(e)"
        >
          <a-radio-button
            v-for="(item) in defaultValues"
            :key="item"
            :value="item"
            :style="{background:item}"
          >
          </a-radio-button>
        </a-radio-group>
      </div>

    </a-input-group>
  </div>
</template>

<script>
export default {
  props:{
    defaultValue:{
      type:String,
      default:'#1890ff'
    },
    mode:{
      type:Number,
      default:1
    },
    defaultValues:{
      type:Array,
      default:()=>[]
    }
  },
  data(){
    return{
      valueX:this.defaultValue
    }
  },
  methods:{
    onColorChange(e){
      this.onColorChangeValue(e.target.value)
    },
    onColorChangeValue(value){
      this.$emit('onColorChange', value)
    },
    radioChange(e){
      this.onColorChangeValue(e.target.value)
    }
  }
}
</script>

<style>
.color-input {
  align-self: center;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 0;
}
</style>