<template>
  <div>
    <a-form
      :model="advancedConfigValue"
      name="basic"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item label="蒙版主题">
        <a-radio-group
          :value="advancedConfigValue.maskingOutMode"
          @change="e=>{advancedConfigValueChange('maskingOutMode',e.target.value)}"
        >
          <a-radio-button :value="0">雪花飘逸</a-radio-button>
          <a-radio-button :value="1">太阳系</a-radio-button>
          <a-radio-button :value="2">渐变（红蓝）</a-radio-button>
          <a-radio-button :value="3">原子漂移</a-radio-button>
          <a-radio-button :value="999">无</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="鼠标点击效果">
        <a-radio-group
          :value="advancedConfigValue.mouseClickStyleMode"
          @change="e=>{advancedConfigValueChange('mouseClickStyleMode',e.target.value)}"
        >
          <a-radio-button :value="0">无</a-radio-button>
          <a-radio-button :value="1">富强民主</a-radio-button>
          <a-radio-button :value="2">烟花绽放</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="鼠标移动效果">
        <a-radio-group
          :value="advancedConfigValue.mouseMoveStyleMode"
          @change="e=>{advancedConfigValueChange('mouseMoveStyleMode',e.target.value)}"
        >
          <a-radio-button :value="0">无</a-radio-button>
          <a-radio-button :value="1">雪花</a-radio-button>
          <a-radio-button :value="2">气泡</a-radio-button>
          <a-radio-button :value="3">梦幻</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="反色">
        <a-switch
          :checked="advancedConfigValue.invertFlag"
          checked-children="开"
          un-checked-children="关"
          @change="value=>{advancedConfigValueChange('invertFlag',value)}"
        />
      </a-form-item>
      <a-form-item label="固定菜单栏">
        <a-switch
          :checked="advancedConfigValue.menuFixedFlag"
          checked-children="开"
          un-checked-children="关"
          @change="value=>{advancedConfigValueChange('menuFixedFlag',value)}"
        />
      </a-form-item>
      <a-form-item label="显示菜单栏">
        <a-switch
          :checked="advancedConfigValue.menuShowFlag"
          checked-children="开"
          un-checked-children="关"
          @change="value=>{advancedConfigValueChange('menuShowFlag',value)}"
        />
      </a-form-item>
      <a-form-item label="菜单栏占位">
        <a-switch
          :checked="advancedConfigValue.menuPositionFlag"
          checked-children="开"
          un-checked-children="关"
          @change="value=>{advancedConfigValueChange('menuPositionFlag',value)}"
        />
      </a-form-item>
      <a-form-item label="恢复默认">
        <a-button
          type="link"
          @click="()=>{clearCache();$router.go(0);}"
        >清除缓存并刷新</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { advancedConfigSetting } from '@/assets/js/defaultSettings.js';
import { clearCache } from '@/assets/js/cache.js'
import { advancedConfigValueChange } from "../SettingCenter.js";
export default {
  data(){
    var advancedConfigValue = advancedConfigSetting.getData();
    return{
      advancedConfigValue,
      clearCache
    }
  },
  methods:{
    advancedConfigValueChange(type,value){
      advancedConfigValueChange(this.advancedConfigValue, type, value)
    }
  }
}
</script>

<style>
</style>