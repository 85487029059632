
import { contentCache } from '@/assets/js/cache.js'

/**
 * 同步初始化默认内容配置，会阻塞进程
 */
const initContentSettings = () => {
  let contentSettings = contentCache.getCache();
  if (contentSettings) {
    console.info("已从缓存中获取到内容配置数据，若使用遇到异常，请手动清除缓存并刷新，即可获取到默认配置")
  }
  else {
    let request = new XMLHttpRequest();
    request.open('GET', 'contentSettings.json', false);
    request.send(null);
    if (request.status === 200) {
      contentSettings = JSON.parse(request.response)
      contentCache.setCache(contentSettings)
      console.info("成功从服务器获取到默认内容配置文件，祝君开心")
    }
    else {
      alert("获取默认内容配置失败，请检查默认内容配置文件contentSettings.json：" + request.responseText)
    }
  }
}

/**
 * 内容设置
 */
const contentSettings = {
  getData: () => {
    let temp = contentCache.getCache()
    if (!temp) {
      initContentSettings();
      return contentCache.getCache()
    }
    return temp;
  },
  setData: (data) => {
    contentCache.setCache(data);
  }
}

/**
 * doc资源目录
 */
const docSource = {
  getData: () => {
    let temp = contentCache.getCache()?.docSource
    if (!temp) {
      initContentSettings();
      return contentCache.getCache()?.docSource
    }
    return temp;
  },
  setData: (data) => {
    if (data) {
      let content = contentSettings.getData()
      content.docSource = data
      contentCache.setCache(content);
    }
  }
}

export { initContentSettings, contentSettings, docSource }
