<!-- https://codepen.io/P1N2O/pen/pyBNzX -->
<template>
  <div class="d-flex flex-column justify-content-center w-100 h-100 gradient-background-body">

    <div class="d-flex flex-column justify-content-center align-items-center">

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.gradient-background-body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  opacity: 0.1;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>