import { createRouter, createWebHashHistory } from 'vue-router'
import myStore from '@/store/store.js'
import axios from 'axios';
import { defaultSettings } from '@/assets/js/defaultSettings.js';
import { contentSettings } from '@/assets/js/contentSettings.js'
import { setTreeKey } from '@/assets/js/tree.js'

const defaultRoute = [
  {
    name: '错误界面',
    path: '/Exception',
    component: () => import('@/views/Default/ExceptionView.vue'),
  }
];

const getRoutes = (arrData) => {
  if (arrData && arrData.length && arrData.length > 0) {
    //树展开转数组
    const getObjects = (arrayData) => {
      if (arrayData && arrayData.length && arrayData.length > 0) {
        let ds = []
        arrayData.forEach(element => {
          //当前节点
          if (element.path) {
            let el = {
              ...element,
              componentPath: element.componentPath
            }
            ds.push(el)
          }
          //子节点
          let elChild = []
          if (element.children) {
            elChild = getObjects(element.children)
            ds.push(...elChild)
          }

        });
        return ds
      }
    }
    let ds = getObjects(arrData)

    //去重
    const unique = (arrayData) => {
      var obj = {}
      let arr = arrayData.reduce(function (a, b) {
        obj[b.path] ? '' : obj[b.path] = true && a.push(b);
        return a;
      }, [])
      return arr;
    }
    let temp = unique(ds)
    let rs = []

    temp.forEach(element => {
      let url = element.componentPath ? element.componentPath : element.path + '.vue'
      rs.push({
        ...element,
        meta: {
          title: element.name
        },
        component: () => require.ensure([], (require) => require(`../views${url}`))//只想来句mmp，import死活不支持动态导入，但一个被取代的却支持
      })
    })
    return rs
  }
  else {
    return []
  }
}
const routes = () => {
  let res = getRoutes(routesData());
  defaultRoute.forEach(el => {
    res.push(el)
  })
  return res;
}

const getMenus = (arrData) => {
  if (arrData && arrData.length && arrData.length > 0) {
    // const setObjectKey = (obj, keyP, index) => {
    //   let key = (keyP ? keyP : '') + (keyP ? '.' : '') + (index ? index : '')
    //   if (obj.children) {
    //     for (let i = 0; i < obj.children.length; i++) {
    //       const element = obj.children[i];
    //       let child = setObjectKey(element, key, i)
    //       obj.children[i] = child
    //     }
    //   }
    //   return { key, ...obj }
    // }
    // let rs = []
    // for (let i = 0; i < arrData.length; i++) {
    //   const element = arrData[i];
    //   let obj = setObjectKey(element, null, '' + i)
    //   rs.push(obj)
    // }
    // return rs
    const setChildrenKey = (obj) => {
      let tr = obj;
      if (tr.children && tr.children.length) {
        for (let i = 0; i < tr.children.length; i++) {
          tr.children[i].key = tr.children[i].name + '-' + tr.children[i].path;
          if (tr.children[i].children && tr.children[i].children.length) {
            tr.children[i] = setChildrenKey(tr.children[i], tr.children[i].key);
          }
        }
        return tr;
      }
      else {
        return tr;
      }
    }

    let temp = { children: arrData };
    let ss = setChildrenKey(temp);
    return ss.children;
  }
  else {
    return []
  }
}

const menus = () => {
  return getMenus(routesData())
}

const routesData = () => {
  // let settings = defaultSettings.getData();
  let settings = contentSettings.getData();
  // console.info("settings:", settings)
  if (!settings) {
    return defaultRoutesData;
  }
  else {
    return settings.mainRoutes
  }
};

const defaultRoutesData = [{
  name: '主页',
  icon: 'CarryOutOutlined',
  path: '/',
  componentPath: '/HomeView.vue',
}, {
  name: 'Template',
  path: '/template',
  icon: 'CarryOutOutlined',
  componentPath: '/TemplatePage.vue'
}, {
  name: 'Game',
  path: '/game',
  icon: 'CarryOutOutlined',
  componentPath: '/GameView.vue'
}, {
  name: 'md文档',
  path: '/documents',
  icon: 'ContainerOutlined',
  componentPath: '/DocView.vue'
}, {
  name: 'md编辑器',
  path: '/markdownEditor',
  icon: 'EditOutlined',
  componentPath: '/MarkdownEditView.vue'
}];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes()
})
var routerTemps = null;
// 全局守卫
router.beforeEach((to, from, next) => {
  let projectName = myStore.defaultSettings.getValue()?.projectName;
  to.meta.title && (document.title = (projectName ? (projectName + '-') : '') + to.meta.title);
  if (!routerTemps) routerTemps = routes();
  if (routerTemps.find(x => x.path == to.path)) {
    myStore.route.setValue(to)
    next()
  } else {
    router.push({ path: '/Exception', query: { type: '404' } })
  }
});

export default router
export {
  // routes,
  getRoutes,
  routes,
  menus
}
