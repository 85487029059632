import axios from 'axios'
import { setingsCache } from '@/assets/js/cache.js'
import { keywordConvert } from '@/assets/js/string.js'
import variables from '@/styles/theme/variables.module.less';
import myStore from "@/store/store.js";
import cache from '@/assets/js/cache.js'
import { setMouseMoveStyle, setMouseClickStyle } from '@/styles/mouse/mouse.js'

/**
 * 同步初始化默认配置，会阻塞进程，需在最前面调用
 */
const initDefaultSettings = () => {
  let defaultSettings = setingsCache.getCache();
  if (defaultSettings) {
    myStore.defaultSettings.setValue(defaultSettings)
    console.info("已从缓存中获取到配置数据，若使用遇到异常，请手动清除缓存并刷新，即可获取到默认配置")
  }
  else {
    let request = new XMLHttpRequest();
    request.open('GET', 'defaultSettings.json', false);
    request.send(null);
    if (request.status === 200) {
      defaultSettings = JSON.parse(request.response)
      console.info("成功从服务器获取到默认配置文件，祝君开心")
    }
    else {
      alert("获取默认配置失败，请检查默认配置文件defaultSettings.json：" + request.responseText)
    }
    myStore.defaultSettings.setValue(defaultSettings)
  }

  //异步初始化默认配置，若要使用，务必要再main.js中app生成前调用
  // return new Promise((resolve, reject) => {
  //   let defaultSettings = setingsCache.getCache();
  //   if (defaultSettings) {
  //     myStore.defaultSettings.setValue(defaultSettings)
  //     resolve(defaultSettings)
  //     // themeSettings.setTheme()
  //   }
  //   else {
  //     axios.get('defaultSettings.json').then(res => {
  //       defaultSettings = res.data;
  //       myStore.defaultSettings.setValue(defaultSettings)
  //       resolve(defaultSettings)
  //       // themeSettings.setTheme()
  //     }).catch(err => {
  //       alert('获取默认配置文件失败，请检查项目中是否包含defaultSettings.json文件');
  //       console.info("配置文件加载错误：", err)
  //       reject(err)
  //     })
  //   }
  // })
}

/**
 * 给设置部分字段复制
 * @param {String} fieldName 字段名
 * @param {Object} value 值
 */
const setSettingFieldData = (fieldName, value) => {
  if (fieldName && fieldName) {
    let settings = myStore.defaultSettings.getValue();
    Object.assign(settings, {
      [fieldName]: value,
    });
    myStore.defaultSettings.setValue(settings);
  }
}

const oldSettingsCacheKey = "oldSettings";
/**
 * 默认全部设置
 */
const defaultSettings = {
  getData: () => {
    let settings = myStore.defaultSettings.getValue()
    if (settings) return settings;
    else {
      initDefaultSettings();
      return myStore.defaultSettings.getValue();
    }
  },
  setData: (data) => {
    if (data) myStore.defaultSettings.setValue(data)
  },
  /**
 * 应用所有设置（内置）
 * @param {Object} settings 设置对象
 */
  applySettings: (settings) => {
    cache.removeCache(oldSettingsCacheKey)
    var oldSettings = cache.getCache(oldSettingsCacheKey);
    if (!oldSettings) {
      oldSettings = {
        advancedConfigValue: {},
        themeModifyVars: {},
        configProviderValue: {}
      }
    }
    if (!settings) {
      settings = defaultSettings.getData();
    }
    if (oldSettings.advancedConfigValue.invertFlag != settings.advancedConfigValue.invertFlag) {
      document.querySelector('#app').style.filter = settings.advancedConfigValue.invertFlag ? 'invert(100%) hue-rotate(180deg)' : '';
    }
    if (oldSettings.themeModifyVars != settings.themeModifyVars) {
      themeSetting.applyTheme();
    }
    if (oldSettings.advancedConfigValue.mouseMoveStyleMode != settings.advancedConfigValue.mouseMoveStyleMode) {
      setMouseMoveStyle(settings.advancedConfigValue.mouseMoveStyleMode);
    }
    if (oldSettings.advancedConfigValue.mouseClickStyleMode != settings.advancedConfigValue.mouseClickStyleMode) {
      setMouseClickStyle(settings.advancedConfigValue.mouseClickStyleMode)
    }

    cache.setCache(oldSettingsCacheKey, settings);
  }
}

/**
 * 主题设置
 */
const themeSetting = {
  /**
   * 获取主题设置，优先获取缓存
   * @returns {Object} 主题设置
   */
  getData: () => {
    let settings = setingsCache.getCache();
    let theme = settings.themeModifyVars ? settings.themeModifyVars : null;
    if (!theme) {
      // 无缓存时候存取内置值
      setSettingFieldData("themeModifyVars", variables)
      theme = variables;
    }
    return theme
  },
  /**
   * 设置主题
   * @param {Object} theme 主题设置
   */
  setData: (theme) => {
    if (!theme) {
      theme = themeSetting.getData()
    }
    // let themeModifyVars = {}
    // for (const i in theme) {
    //   if (Object.prototype.hasOwnProperty.call(theme, i)) {
    //     let key = keywordConvert.toHyphen(i)
    //     key = '@' + key;
    //     Object.assign(themeModifyVars, {
    //       [key]: theme[i],
    //     });
    //   }
    // }
    // window.less.modifyVars(themeModifyVars)
    setSettingFieldData("themeModifyVars", theme)
  },
  /**
   * 应用主题
   * @param {Object} theme 主题设置
   */
  applyTheme: (theme) => {
    if (!theme) {
      theme = themeSetting.getData()
    }
    let themeModifyVars = {}
    for (const i in theme) {
      if (Object.prototype.hasOwnProperty.call(theme, i)) {
        let key = keywordConvert.toHyphen(i)
        key = '@' + key;
        Object.assign(themeModifyVars, {
          [key]: theme[i],
        });
      }
    }
    window.less.modifyVars(themeModifyVars)
  }
}

/**
 * 高级设置
 */
const advancedConfigSetting = {
  getData: () => {
    return myStore.defaultSettings.getValue()?.advancedConfigValue;
  },
  setData: (data) => {
    setSettingFieldData("advancedConfigValue", data)
  }
}

/**
 * antd的组件全局化配置
 */
const configProviderSetting = {
  getData: () => {
    return myStore.defaultSettings.getValue()?.configProviderValue;
  },
  setData: (data) => {
    setSettingFieldData("configProviderValue", data)
  }
}

// const defaultSettings = {
//   initDefaultSettings,
//   themeSetting,
//   advancedConfigSetting,
//   configProviderSetting
// }


export default defaultSettings

export { initDefaultSettings, defaultSettings, themeSetting, advancedConfigSetting, configProviderSetting }