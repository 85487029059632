<template>
  <div>
    <!-- <span style="position: fixed; display: block; pointer-events: none; z-index: 10000000; font-size: 55px;">❄️</span> -->
    <a-config-provider
      :direction="antdConfigProviderValue.direction"
      :componentSize="antdConfigProviderValue.componentSize"
    >
      <!-- <ParticlesView></ParticlesView> -->
      <a-layout id="layout-main">
        <a-layout>
          <a-layout-header
            v-show="advancedConfigValue.menuShowFlag"
            id="custom-header"
          >
            <a-row>
              <a-col :span="1">
                <div @click="openMenu()">
                  <img
                    src="/favicon.ico"
                    alt="标识"
                  />
                </div>
              </a-col>
              <a-col :span="21">
                <sub-menu v-if="isPC"></sub-menu>
              </a-col>
              <a-col :span="1"></a-col>
              <a-col :span="1">
                <div id="widgets">
                  <generative-rorschach-inkblot></generative-rorschach-inkblot>
                </div>
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content id="custom-content">
            <!-- <router-view /> -->
            <router-view :style="{marginTop: advancedConfigValue.menuPositionFlag ? '64px' : '0'}" />
          </a-layout-content>
          <a-layout-footer
            id="custom-footer"
            :style="{ textAlign: 'center' }"
          >
            {{store.state.defaultSettings.copyright}}
            <p><img
                src="https://img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png"
                :style="{width: '20px',margin:'0 auto'}"
              /><a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                :style="{verticalAlign: 'middle',margin:'0 auto'}"
              >{{store.state.defaultSettings.registrationNumber}}</a></p>
          </a-layout-footer>
        </a-layout>
        <div id="tools">
          <a-dropdown placement="top">
            <template #overlay>
              <qucik-setting></qucik-setting>
            </template>
            <a-button
              shape="circle"
              @click="openSetting"
            >
              <template #icon>
                <component :is="$antIcons['SettingOutlined']" />
              </template>
            </a-button>
          </a-dropdown>
        </div>

        <a-drawer
          v-if="!isPC"
          width="60%"
          title="菜单"
          placement="left"
          :visible="!collapsed"
          @close="()=>{this.collapsed = true}"
        >
          <!-- <template #extra>
            <a-button style="margin-right: 8px" @click="onClose">Cancel</a-button>
            <a-button type="primary" @click="onClose">Submit</a-button>
          </template> -->
          <sub-menu mode="inline"></sub-menu>
        </a-drawer>
        <masking-out :mode="advancedConfigValue.maskingOutMode"></masking-out>
        <setting-center v-model:visible="settingCenterVisible"></setting-center>
      </a-layout>
      <a-back-top />
    </a-config-provider>
  </div>
</template>

<script>
// Field Tools ©2022 Created by Field
import { ref, watch} from 'vue';
import {IsPC, GetBrowserType} from "@/assets/js/device.js"
import SubMenu from '@/components/SubMenu'
import MaskingOut from '@/components/MaskingOut/MaskingOut'
import defaultSettings from '@/assets/js/defaultSettings.js'
import store from '@/store/index.js'
import SettingCenter from '@/components/SettingCenter/SettingCenter.vue'
import QucikSetting from '@/components/SettingCenter/QucikSetting'
import GenerativeRorschachInkblot from '@/components/Widgets/GenerativeRorschachInkblot'
// import ParticlesView from '@/components/Particles/Index.vue'

export default {
  components:{
    SubMenu,
    MaskingOut,
    SettingCenter,
    QucikSetting,
    GenerativeRorschachInkblot
    // ParticlesView
  },
  setup(){
    // console.info("窗口载入")
    // console.info("当前浏览器是否是pc：",IsPC())
    // console.info("当前浏览器类型：",GetBrowserType())
    defaultSettings.applySettings()
    var advancedConfigValue = store.state.defaultSettings.advancedConfigValue;
    var antdConfigProviderValue = store.state.defaultSettings.configProviderValue;
    var visualSettingFlag = store.state.defaultSettings.visualSettingFlag;
    return{
      antdConfigProviderValue,
      advancedConfigValue,
      visualSettingFlag
    }
  },
  data(){
    var isPC = IsPC();
    return{
      selectedKeys: ref([0]),
      visible:false,
      // routes,
      isPC,
      collapsed:true,
      headerShowFlag:true,
      footerShowFlag:true,
      store,
      settingCenterVisible:false,
      headerPosition:''
    }
  },
  mounted(){
    const setSettings = (settings)=>{
      this.advancedConfigValue = settings.advancedConfigValue;
      this.antdConfigProviderValue = settings.configProviderValue;
      // this.headerPosition = settings.advancedConfigValue.menuFixedFlag ? 'fixed' : '';
      if(document.querySelector('#custom-header')) document.querySelector('#custom-header').style.position = settings.advancedConfigValue.menuFixedFlag ? "fixed" : "";

    }
    setSettings(defaultSettings.getData());
    //监听设置中需要本级组件触发的情况
    watch(()=>store.state.defaultSettings,(newValue)=>{
      setSettings(newValue)
    },{deep:true})

    window.addEventListener('resize', ()=>{
      this.isPC = IsPC();
    });
  },
  methods:{
    openSetting(){
      if(this.visualSettingFlag){
        console.info("打开可视化设置面板")
        this.settingCenterVisible = !this.settingCenterVisible
      }
    },
    openMenu(){
      if(!IsPC()){
        this.collapsed = !this.collapsed
      }
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  // color: #2c3e50;
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
/* .site-layout .site-layout-background {
  background: #fff;
} */

/* [data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
} */

#layout-main {
  /* display: flex; */
  /* text-align: center; */
  min-height: 100%; /*保证父节点至少占满高度*/
  flex-flow: row wrap; /* 使用行布局，并可以换行。 */
  background: transparent;
  /* opacity: 0.8; */
}

#custom-header {
  /* align-self: flex-start; */
  height: 64px;
  background: transparent;

  // position: v-bind("headerPosition");
  z-index: 1;
  width: 100%;
  padding: 0 10px;
  text-align: center;
}

#custom-content {
  /* align-self: flex-start; */
  /* position: absolute; */
  background: transparent;
  /* text-align: center; */
  min-height: 100vh;
}

#custom-footer {
  width: 100%;
  // height: 5vh;
  background: transparent;
  /* align-self: flex-end; */
  /* top: 90%;*/
  /* bottom: 0; */
  /* margin-top: 5vh; */
  /* position: absolute; */
}

#tools {
  position: fixed;
  right: 5vh;
  bottom: 5vh;
  z-index: 100;
}

section :deep(.ant-layout) {
  background: v-bind(
    "store.state.defaultSettings.themeModifyVars.backgroundColor"
  );
}

#widgets {
  // position: fixed;
  margin: 15px;
  max-height: 50px;
  max-width: 50px;
}
</style>
