<template>
  <div>
    <!-- <a-popover
      placement="topLeft"
      title="色彩主题设置"
      v-model:visible="visible"
      trigger="click"
    >
      <template #content> -->
    <!-- <div class="setting-popover"> -->
    <a-form
      :model="themeColors"
      name="basic"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-collapse
        v-model:activeKey="activeKey"
        accordion
        ghost
      >
        <a-collapse-panel
          key="1"
          header="颜色"
        >
          <a-form-item label="全局主色">
            <color-editor
              :mode="3"
              :defaultValue="themeColors.primaryColor"
              :defaultValues="primaryColors"
              @onColorChange="(value)=>{onColorChange('primaryColor', value)}"
            ></color-editor>
          </a-form-item>

          <a-form-item label="全景背景色">
            <color-editor
              :defaultValue="themeColors.backgroundColor"
              @onColorChange="(value)=>{onColorChange('backgroundColor', value)}"
            ></color-editor>
          </a-form-item>

          <a-form-item label="错误色">
            <color-editor
              :defaultValue="themeColors.errorColor"
              @onColorChange="(value)=>{onColorChange('errorColor', value)}"
            ></color-editor>
          </a-form-item>

          <a-form-item label="警告色">
            <color-editor
              :defaultValue="themeColors.warningColor"
              @onColorChange="(value)=>{onColorChange('warningColor', value)}"
            ></color-editor>
          </a-form-item>
          <a-form-item label="加载色">
            <color-editor
              :defaultValue="themeColors.processingColor"
              @onColorChange="(value)=>{onColorChange('processingColor', value)}"
            ></color-editor>
          </a-form-item>

          <a-form-item label="成功色">
            <color-editor
              :defaultValue="themeColors.successColor"
              @onColorChange="(value)=>{onColorChange('successColor', value)}"
            ></color-editor>
          </a-form-item>
          <a-form-item label="消息色">
            <color-editor
              :defaultValue="themeColors.infoColor"
              @onColorChange="(value)=>{onColorChange('infoColor', value)}"
            ></color-editor>
          </a-form-item>
          <a-form-item label="主文本色">
            <color-editor
              :defaultValue="themeColors.textColor"
              @onColorChange="(value)=>{onColorChange('textColor', value)}"
            ></color-editor>
          </a-form-item>
        </a-collapse-panel>
        <a-collapse-panel
          key="2"
          header="其他"
        >
          <p>{{ text }}</p>
        </a-collapse-panel>
      </a-collapse>
    </a-form>
    <!-- </template>
      <div @click="()=>{visible = !visible;}">色彩主题设置</div>
    </a-popover> -->
  </div>
</template>

<script>
import {themeSetting} from '@/assets/js/defaultSettings.js'
import ColorEditor from '@/components/SettingCenter/Color/ColorEditor'
import { themeVarChange } from '../SettingCenter.js'
const primaryColors = ['#bca42c','#1890ff','#52c41a','#faad14','#ea24ea','#141414']
export default {
  components:{
    ColorEditor
  },
  setup(){
  },
  data(){
    var themeColors = themeSetting.getData();
    return{
      themeColors,
      visible:false,
      primaryColors,
      activeKey:['1'],
      text:"待定~",
    }
  },
  mounted(){
    // this.flushColor()
  },
  methods:{
    onColorChange(type, value) {
      themeVarChange(this.themeColors, type, value)
    }
  }
}
</script>

<style lang="scss">
.setting-popover {
  width: 25vw;
  height: 25vh;
}
</style>