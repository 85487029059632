import store from "@/store/index.js";
/**
 * 保存数据
 * @param {String} state state字段名
 * @param {Object} data 数据
 */
const subStoreData = (state, data) => {
  if (data && state) store.dispatch(state, data)
}

/**
 * 设置路由全局变量
 * @param {Object} data 路由
 */
const subRoute = (data) => {
  subStoreData('subRoute', data)
}

/**
 * 设置默认设置全局变量
 * @param {Object} data 默认设置
 */
const subDefaultSettings = (data) => {
  subStoreData('subDefaultSettings', data)
}

/**
 * 设置鼠标主题全局变量
 * @param {Object} data 鼠标主题
 */
const subMouseStyle = (data) => {
  subStoreData('subMouseStyle', data)
}

/**
 * vuex全局变量
 */
const myStore = {
  route: {
    getValue: () => store.state.route,
    setValue: (data) => { subRoute(data) },
  },
  defaultSettings: {
    getValue: () => store.state.defaultSettings,
    setValue: (data) => { subDefaultSettings(data) }
  },
  mouseStyle: {
    getValue: () => store.state.mouseStyle,
    setValue: (data) => { subMouseStyle(data) }
  }
}

export default myStore