<template>
  <div>
    <a-modal
      :visible="visible"
      ref="modalRef"
      @ok="handleOk"
      @cancel="handleOk"
      :bodyStyle="{padding:'5px 15px',minHeight:'200px'}"
    >
      <div v-if="codeFlag">
        <div>该配置仅在缓存清除前可生效，清除后所有配置恢复默认</div>
        <a-tooltip>
          <template #title>
            慎重操作，若导致网页无法正常运行，请清除网页缓存并刷新
          </template>
          <a-textarea
            v-model:value="settingsCode"
            :rows="8"
          ></a-textarea>
        </a-tooltip>
      </div>
      <div v-else>
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane
            key="1"
            tab="主题"
          >
            <color-setting></color-setting>
          </a-tab-pane>
          <a-tab-pane
            key="2"
            tab="布局"
          >
            <layout-setting></layout-setting>
          </a-tab-pane>
          <a-tab-pane
            key="3"
            tab="高级"
          >
            <advanced-setting></advanced-setting>
          </a-tab-pane>
          <a-tab-pane
            key="4"
            tab="关于"
          >
            <about-content></about-content>
          </a-tab-pane>
        </a-tabs>
      </div>
      <template #title>
        <div
          ref="modalTitleRef"
          style="width: 100%; cursor: move"
        >设置中心
          <a-tooltip>
            <template #title>{{codeFlag?'切换到常规视图':'切换到代码视图'}}</template>
            <a-button @click="changeCodeView">
              <template #icon>
                <component :is="$antIcons['RetweetOutlined']" />
              </template>
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #footer>
        <div>
          <a-button @click="close">取消</a-button>
          <a-button
            v-if="codeFlag"
            @click="handleApply"
          >应用</a-button>
          <a-button
            type="primary"
            @click="handleOk"
          >确定</a-button>
        </div>
      </template>
      <template
        v-if="IsPC()"
        #modalRender="{ originVNode }"
      >
        <div :style="transformStyle">
          <component :is="originVNode" />
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, computed, watch, watchEffect } from 'vue';
import { useDraggable } from '@vueuse/core';
import ColorSetting from '@/components/SettingCenter/Color/ColorSetting'
import LayoutSetting from '@/components/SettingCenter/Layout/LayoutSetting'
import AdvancedSetting from '@/components/SettingCenter/Advanced/AdvancedSetting'
import AboutContent from '@/components/SettingCenter/AboutContent'
import {themeSetting,advancedConfigSetting,configProviderSetting} from '@/assets/js/defaultSettings.js'
import defaultSettings from '@/assets/js/defaultSettings.js'
import {IsPC} from "@/assets/js/device.js"
import store from '@/store/index.js'
export default {
  components:{
    ColorSetting,
    LayoutSetting,
    AdvancedSetting,
    AboutContent
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    }
  },
  setup(){
    const modalTitleRef = ref(null);

    const {
      x,
      y,
      isDragging,
    } = useDraggable(modalTitleRef);

    const startX = ref(0);
    const startY = ref(0);
    const startedDrag = ref(false);
    const transformX = ref(0);
    const transformY = ref(0);
    const preTransformX = ref(0);
    const preTransformY = ref(0);
    const dragRect = ref({
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    });
    watch([x, y], () => {
      if (!startedDrag.value) {
        startX.value = x.value;
        startY.value = y.value;
        const bodyRect = document.body.getBoundingClientRect();
        const titleRect = modalTitleRef.value.getBoundingClientRect();
        dragRect.value.right = bodyRect.width - titleRect.width;
        dragRect.value.bottom = bodyRect.height - titleRect.height;
        preTransformX.value = transformX.value;
        preTransformY.value = transformY.value;
      }

      startedDrag.value = true;
    });
    watch(isDragging, () => {
      if (!isDragging) {
        startedDrag.value = false;
      }
    });
    watchEffect(() => {
      if (startedDrag.value) {
        transformX.value = preTransformX.value + Math.min(Math.max(dragRect.value.left, x.value), dragRect.value.right) - startX.value;
        transformY.value = preTransformY.value + Math.min(Math.max(dragRect.value.top, y.value), dragRect.value.bottom) - startY.value;
      }
    });
    const transformStyle = computed(() => {
      return {
        transform: `translate(${transformX.value}px, ${transformY.value}px)`,
      };
    });
    return {
      modalTitleRef,
      transformStyle,
      IsPC
    };
  },
  data(){
    var settingsCode = JSON.stringify(defaultSettings.getData(),null,'\t');
    var codeFlag = false;
    return{
      activeKey:'1',
      codeFlag,
      settingsCode
    }
  },
  mounted(){

    watch(()=>store.state.defaultSettings,(newValue)=>{
      //需要传递到父组件才能生效的值，请在父组件进行监听，无需使用v-model

      //组件内即可实现的部分
      defaultSettings.applySettings(newValue)
    },{deep:true})
  },
  methods:{
    close(){
      this.$emit("update:visible",false)
    },
    handleApply(){
      if(this.codeFlag){
        let settings = JSON.parse(this.settingsCode);
        let themeColors = settings.themeModifyVars;
        let configProviderValue = settings.configProviderValue;
        let advancedConfigValue = settings.advancedConfigValue;
        //主题色
        themeSetting.setData(themeColors)
        //antd组件布局
        configProviderSetting.setData(configProviderValue)
        //高级设置
        advancedConfigSetting.setData(advancedConfigValue)
      }
    },
    handleOk(){
      this.handleApply()
      //关闭窗口
      this.close()
      //传递给父组件的确认点击后的操作
      this.$emit("handleOk", null)
    },
    //改变为代码视图时候调用，因为并非挂载组件，其切换时不会重新加载，可能无法加载常规模式下更新的设置字段
    changeCodeView(){
      this.codeFlag = !this.codeFlag;
      if(this.codeFlag){
        this.settingsCode = JSON.stringify(defaultSettings.getData(),null,'\t');
      }
    }
  }
}
</script>

<style>
</style>