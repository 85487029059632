<template>
  <div>
    <a-form
      :model="antdConfigProviderValue"
      name="basic"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-item label="antd组件布局方向：">
        <a-radio-group
          :value="antdConfigProviderValue.direction"
          @change="e=>{antdConfigProviderValueChange('direction',e)}"
        >
          <a-radio-button value="ltr">从左往右</a-radio-button>
          <a-radio-button value="rtl">从右往左</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="antd组件基础大小：">
        <a-radio-group
          :value="antdConfigProviderValue.componentSize"
          @change="e=>{antdConfigProviderValueChange('componentSize',e)}"
        >
          <a-radio-button value="small">小</a-radio-button>
          <a-radio-button value="middle">中</a-radio-button>
          <a-radio-button value="large">大</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { configProviderSetting } from '@/assets/js/defaultSettings.js';
import { antdConfigProviderValueChange } from "../SettingCenter.js";
export default {
  data(){
    var antdConfigProviderValue = configProviderSetting.getData();
    return{
      antdConfigProviderValue
    }
  },
  methods:{
    antdConfigProviderValueChange(type,e){
      antdConfigProviderValueChange(this.antdConfigProviderValue, type, e.target.value)
    }
  }
}
</script>

<style>
</style>