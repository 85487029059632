<template>
  <div>

    <a-collapse
      v-model:activeKey="activeKey"
      accordion
      ghost
    >
      <a-collapse-panel
        key="0"
        header="念念叨叨"
      >
        <div>这个项目作为练手项目，本身想要实现的功能很简单，
          只是根据配置文件动态加载页面的纯静态网页而已。
          但因为一些执念，断断续续写了一两个月，搞了各种各样花里胡哨的东西，
          比如添加了各种各样的用户动态自定义配置、竖屏适配、自定义跳转等。
          回过头来一看，md，好像是有点浪费精力和时间了</div>
      </a-collapse-panel>
      <a-collapse-panel
        key="1"
        header="项目部分参考内容"
      >
        <div>
          <a-list
            size="small"
            :data-source="contentData"
            :pagination="pagination"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                <a-list-item-meta>
                  <template #title>
                    <a
                      href="https://www.antdv.com/"
                      target="_blank"
                    >{{ item.title }}</a>
                  </template>
                  <template #description>
                    {{ item.description }}
                  </template>
                  <!-- <template #avatar>
            <a-avatar src="https://joeschmoe.io/api/v1/random" />
          </template> -->
                </a-list-item-meta>
              </a-list-item>
            </template>
          </a-list>
        </div>
      </a-collapse-panel>
      <a-collapse-panel
        key="2"
        header="其他"
      >
        <p>555555</p>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
const contentData = [
  {title:"vue 前端框架",url:"https://cn.vuejs.org/",description:"目前我最喜欢使用的一个前端框架，很nb"},
  {title:"antd vue UI组件",url:"https://www.antdv.com/",description:"开箱即用，很方便"},
  {title:"w3cshcool web前端系列教程",url:"https://www.w3school.com.cn/h.asp",description:"资料很好查"},
  {title:"codepen平台",url:"https://codepen.io/trending",description:"在线前端演示平台，有诸多开源方案可供参考"},
  {title:"antd-theme-webpack-plugin主题管理插件",url:"https://juejin.cn/post/7057325201171677214",description:"动态主题变量方案"},
  {title:"VUEX状态管理仓库",url:"https://vuex.vuejs.org/zh/guide/",description:"全局变量方案"},
  {title:"v-md-editor markdown编辑器组件",url:"https://code-farmer-i.github.io/vue-markdown-editor/zh/",description:"支持html、emoji、katex、mermaid、todo-list等"},
  {title:"MDN Plus web开发文档",url:"https://developer.mozilla.org/en-US/plus",description:"文档更新，某种程度来说，比w3cshcool更好用"},
  
  {title:"右上角的小部件 Generative Rorschach Inkblot(洛夏墨迹测验) ",url:"https://codepen.io/robdimarzo/pen/QWxWoeG",description:"尝试点击一下"},
  {title:"蒙版主题1 Solar System(太阳系)",url:"https://codepen.io/kowlor/pen/ZYYQoy",description:"来源于codepen Malik Dellidj"},
  {title:"蒙版主题2 GradientBackground(渐变背景)",url:"https://codepen.io/P1N2O/pen/pyBNzX",description:"来源于codepen Malik Dellidj"},
  {title:"蒙版主题3 Snow(雪花)",url:"https://codepen.io/alphardex/full/dyPorwJ",description:"来源于codepen alphardex"},
  {title:"蒙版主题4 Molecule Orbit(分子轨道)",url:"https://codepen.io/awinnett/pen/mdLOpro",description:"来源于codepen Austin"},
  
  {title:"鼠标移动特效参考",url:"https://codepen.io/tholman/pens/public",description:"来源于codepen Tim Holman"},
]
export default {
  data(){
    const pagination = {
      onChange: page => {
        // console.log(page);
      },
      pageSize: 3,
    };
    return{
      contentData,
      activeKey:['0'],
      pagination
    }
  }
}
</script>

<style>
</style>