import { createApp } from 'vue';
import App from './App.vue';
//vuex
import store from './store/index.js'
import router from './router';
// import { routes2 } from './router'
import Antd from 'ant-design-vue/es';
// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.variable.min.css';
// import '@/styles/theme/variables.module.less'
// import 'ant-design-vue/dist/antd.dark.css';

import { VMdPreview, VMdPreviewHtml, VueMarkdownEditor } from "@/assets/js/v-md-editor";

const app = createApp(App);

//动态挂载antd vue 3.x的icon的方案，全局加载，使用方式：<component :is="$antIcons[item.icon]" />
//导入组件库
import * as antIcons from '@ant-design/icons-vue'
// 注册组件
Object.keys(antIcons).forEach(key => {
  app.component(key, antIcons[key])
})
// 添加到全局
app.config.globalProperties.$antIcons = antIcons

//particles
// import particles from 'particles.js'

//默认配置使用同步载入的方式，在获取路由之前进行载入，实际上，前端不应该使用同步http请求，这会导致线程阻塞，看起来像卡了一样

app.use(router).use(Antd).use(VMdPreview).use(VMdPreviewHtml).use(VueMarkdownEditor).use(store).mount('#app');

