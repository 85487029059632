import { createStore } from 'vuex'
//使用缓存辅助，避免某些值在页面刷新后无初始值
import { routerCache, setingsCache } from "@/assets/js/cache.js";

export default createStore({
  state: {
    //
    title: [],
    //当前路由
    route: null,
    //配置文件默认设置
    defaultSettings: null,
    //鼠标效果对象
    mouseStyle: null,
  },
  mutations: {
    title2(state, ti) {//名字不能和actions里重复
      state.title = ti;
    },
    subRouteX(state, route) {
      state.route = route;
      routerCache.setCache(route)
    },
    subDefaultSettingsX(state, defaultSettings) {
      state.defaultSettings = defaultSettings;
      setingsCache.setCache(defaultSettings)
    },
    subMouseStyleX(state, mouseStyle) {
      state.mouseStyle = mouseStyle;
    }
  },
  actions: {
    title(context, ti) {
      context.commit('title2', ti);
    },
    subRoute(context, route) {
      context.commit('subRouteX', route);
    },
    subDefaultSettings(context, defaultSettings) {
      context.commit('subDefaultSettingsX', defaultSettings);
    },
    subMouseStyle(context, mouseStyle) {
      context.commit('subMouseStyleX', mouseStyle);
    }
  },
  modules: {

  }
})
