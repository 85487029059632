
/**
 * 关键字转换工具
 */
const keywordConvert = {
  /**
   * 驼峰转下划线
   */
  toLowerLine: (str) => {
    var temp = str.replace(/[A-Z]/g, (match) => {
      return "_" + match.toLowerCase();
    });
    if (temp.slice(0, 1) === '_') { //如果首字母是大写，执行replace时会多一个_，这里需要去掉
      temp = temp.slice(1);
    }
    return temp;
  },
  /**
   * 下划线转驼峰
   * return 
   */
  lowerLineToCamel: (str) => {
    return str.replace(/([^_])(?:_+([^_]))/g, ($0, $1, $2) => {
      return $1 + $2.toUpperCase();
    });
  },
  /**
   * 驼峰转连字符
   */
  toHyphen: (str) => {
    var temp = str.replace(/[A-Z]/g, (match) => {
      return "-" + match.toLowerCase();
    });
    if (temp.slice(0, 1) === '-') { //如果首字母是大写，执行replace时会多一个_，这里需要去掉
      temp = temp.slice(1);
    }
    return temp;
  },
  /**
   * 连字符转驼峰
   */
  hyphenToCamel: (str) => {
    return str.replace(/([^-])(?:-+([^-]))/g, ($0, $1, $2) => {
      return $1 + $2.toUpperCase();
    });
  }
}

/**
 * 截取
 * @param {String} str 需要截取的字符串
 * @param {Number} cutOffLength 截取长度
 * @param {String} suffix 补充后缀
 * @returns 截取后的字符串
 */
const strCutOff = (str, cutOffLength, suffix) => {
  if (str) {
    if (str.length) {
      if (cutOffLength > str.length) {
        return str + suffix;
      }
      else {
        return str.substring(0, cutOffLength) + suffix;
      }
    }
  }
  else {
    return str;
  }
}

export { keywordConvert, strCutOff }