/**
 * 通过判断浏览器的userAgent，用正则来判断是否是ios和Android客户端
 * @returns 1为android，2为ios
 */
const AndroidOrIOS = () => {
  // var u = navigator.userAgent;
  // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // alert('是否是Android：'+isAndroid);
  // alert('是否是iOS：'+isiOS);

  //或者直接用正则，i是忽略大小写
  if (/android/i.test(navigator.userAgent)) {
    console.log("Android");//Android
    return 1
  }
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    console.log("iOS");//iOS
    return 2
  }
}

//方法1
/**
 * 判断是否是pc平台
 * @returns 是否是pc平台
 */
const IsPC = () => {
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }
  }
  return flag;
  // //方法2
  // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
  //   //alert(navigator.userAgent);  
  //   window.location.href ="iPhone.html";
  // } else if (/(Android)/i.test(navigator.userAgent)) {
  //   //alert(navigator.userAgent); 
  //   window.location.href ="Android.html";
  // } else {
  //   window.location.href ="pc.html";
  // };
}

/**
 * 获取浏览器类型
 */
const GetBrowserType = () => {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
  var isIE = userAgent.indexOf("compatible") > -1
    && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
  var isEdge = userAgent.indexOf("Edg") > -1; //判断是否IE的Edge浏览器，其使用了Chrome和Safari，因此优先级要更高才行
  var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
  var isSafari = userAgent.indexOf("Safari") > -1
    && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
  var isChrome = userAgent.indexOf("Chrome") > -1
    && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    if (fIEVersion == 7) {
      return "IE7";
    } else if (fIEVersion == 8) {
      return "IE8";
    } else if (fIEVersion == 9) {
      return "IE9";
    } else if (fIEVersion == 10) {
      return "IE10";
    } else if (fIEVersion == 11) {
      return "IE11";
    } else {
      return "IE";
    }
    //IE版本过低
    // return "IE";
  }
  if (isOpera) {
    return "Opera";
  }
  if (isEdge) {
    return "Edge";
  }
  if (isFF) {
    return "FF";
  }
  if (isSafari) {
    return "Safari";
  }
  if (isChrome) {
    return "Chrome";
  }
}

export {
  AndroidOrIOS,
  IsPC,
  GetBrowserType
}
